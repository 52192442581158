import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-floor',
  standalone: true,
  imports: [],
  templateUrl: './floor.component.html',
  styleUrl: './floor.component.scss'
})
export class FloorComponent implements OnInit {
  public href: string = "";

  constructor() {
  }

  ngOnInit(): void {
    this.href = window.location.origin;
    window.location.href = (environment.url == '' ? this.href : environment.url) + '/floor';
  }
}
