import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EventsPageComponent} from "./pages/events-page/events-page.component";
import {ClassesComponent} from "./pages/classes/classes.component";
import {ReasonsComponent} from "./pages/reasons/reasons.component";
import {FloorComponent} from "./pages/floor/floor.component";

const routes: Routes = [
  {path: 'downtime', component: EventsPageComponent, pathMatch: 'full'},
  {path: 'floor', component: FloorComponent, pathMatch: 'full'},
  {path: 'floor/**', component: FloorComponent, pathMatch: 'full'},
  {path: '', pathMatch: 'full', redirectTo: 'downtime'},
  {path: 'classes', component: ClassesComponent},
  {path: 'reasons', component: ReasonsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
